<template>
  <div class="help">
    <card-header-body ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <router-link
          :to="item.routeName"
          v-for="(item, index) in listContent"
          :index="index"
          :key="item.id"
          class="
            d-flex
            justify-content-between
            align-items-center
            content__item
          "
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">{{ item.name }}</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </router-link>
        <router-link
          v-if="user.sex === 2"
          to="change-password"
          class="
          d-flex
          justify-content-between
          align-items-center
          content__item
          "
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">パスワード更新</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </router-link>
        <div
          v-if="user.sex === 2"
          class="
            d-flex
            justify-content-between
            align-items-center
            content__item
          "
          @click="$refs.modalDeleteUser.openModal()"
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">退会の申請</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </div>
      </div>
    </div>
    <ModalLayout ref="modalDeleteUser">
      <p class="modal-logout-title f-w3">
        アプリ内に登録されている情報やポイント等すべて消えてしまいます。<br />
        {{ user.sex === 1 ? "残ポイント" : "残コイン" }}({{
          Intl.NumberFormat()
            .format(
              user.sex === 1 ? user.pg_points_balance : user.bd_points_balance
            )
            .replace(/\./g, ",")
        }})<br />
        このまま退会しますか？
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button
          @click.prevent="
            $refs.modalAcceptDeleteUser.openModal();
            $refs.modalDeleteUser.closeModal();
          "
          class="btn-cancel-logout"
        >
          はい
        </button>
        <button @click="$refs.modalDeleteUser.closeModal()" class="btn-logout">
          いいえ
        </button>
      </div>
    </ModalLayout>
    <ModalLayout ref="modalAcceptDeleteUser">
      <p class="modal-logout-title f-w3">
        本当に退会しますか？
      </p>
      <div class="d-flex justify-content-center f-w3">
        <button @click.prevent="deleteUser()" class="btn-cancel-logout">
          はい
        </button>
        <button
          @click="$refs.modalAcceptDeleteUser.closeModal()"
          class="btn-logout"
        >
          いいえ
        </button>
      </div>
    </ModalLayout>
    <card-footer-body ref="footer" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import Cookies from "js-cookie";
import firebase from "firebase";

export default {
  name: "Help",
  components: {
    "card-header-body": CardHeader,
    "card-footer-body": CardFooter
  },
  metaInfo() {
    return {
      title: "ヘルプ",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "ヘルプ",
        isShowTitle: true,
        isShowLogo: false
      },
      listContent: [
        {
          id: 1,
          name: "よくあるご質問",
          routeName: "FAQ"
        },
        {
          id: 2,
          name: "利用規約・サブスクリプション契約",
          routeName: "term-of-use"
        },
        {
          id: 3,
          name: "プライバシーポリシー",
          routeName: "Policy"
        },
        {
          id: 4,
          name: "特定商取引法に基づく表記",
          routeName: "Law"
        }
        // {
        //   id: 5,
        //   name: "パスワード更新",
        //   routeName: "change-password"
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    async deleteUser() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("userApp/deleteUser")
        .then(response => {
          this.$root.$refs.loading.finish();
          if (response?.message && !response.success) {
            this.$refs.modalAcceptDeleteUser.closeModal();
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          Cookies.remove("prego_token");
          firebase.auth().signOut();
          this.$router.push({
            name: "TopRegister",
            params: { delete: "success" }
          });
          // this.$toast("宛名数と人数が異なります。", "不合格", "success");
        })
        .catch(() => {
          this.$refs.modalAcceptDeleteUser.closeModal();
          this.$toast("ユーザーの削除に失敗しました", "不合格", "danger");
          this.$root.$refs.loading.finish();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/common/help.scss";

.help {
  /deep/.app-modal.default .app-modal__dialog {
    width: 375px;
  }
}

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}
.modal-logout-title {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 500px;
}
.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
}
.btn-cancel-logout {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}
</style>
